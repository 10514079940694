"use client";

import { AuthError } from "next-auth";
import { signIn } from "next-auth/react"
import { buttonVariants } from "~/ui/button";


export function SignInButton() {
    const handleSignIn = async () => {
        try {
            await signIn("auth0", {
                redirectTo: "/dashboard",
            });
        } catch (error) {
            if (error instanceof AuthError) {
                //console.error("An error occurred while trying to sign in", error);
            }
        }
    };

    return (
        <button
            onClick={handleSignIn}
            className={buttonVariants({
                variant: "default",
                className: "group",
                size: "lg",
              })}
        >
            Login
        </button>
    );
};

export default SignInButton;